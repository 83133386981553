<template>
  <div class="renewable-energy" :class="{ mobile: isPhone }">
    <Hero heading="Renewable Energy &amp; Sustainability" :bgColor="false">
      <template #image>
        <img
          :src="
            !isPhone
              ? require('../assets/images/Renewable-Energy/hero.webp')
              : require('../assets/images/Renewable-Energy/hero-mobile.webp')
          "
          alt="Two EVs charging under an Electrify America canopy"
        />
      </template>
      <h3>Now Backed by 100% Renewable Energy</h3>
      <p>
        One of Electrify America’s primary goals is to support Zero-Emission Vehicle adoption in the United States, but
        we’re not stopping there. Electrify America is committed to a net zero carbon footprint for all energy delivered
        to drivers. Today, when you charge with us, the energy delivered from our network is backed by 100% renewable
        energy from Electrify America Solar Glow™ 1 and other renewable energy certificates.
      </p>
    </Hero>

    <Card headlineLevel="h2" heading="Our Focus on Additionality" headlineDisplayClass="headline3" bgColor>
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/electrify-america-solar-glow-1.webp"
            alt="An aerial view of solar panels on a solar farm"
          />
        </picture>
      </template>
      <h4>Electrify America Solar Glow&trade; 1: Our First Solar Farm</h4>
      <p>
        With over 200,000 solar panels, our first solar farm in Southern California generates an estimated 75 megawatts
        at peak capacity and produced 225 gigawatt-hours of renewable energy in its first year. This is an important
        milestone in our commitment to reduce our energy carbon footprint, improve efficiency, and elevate the customer
        experience.
      </p>
      <a class="button-link" href="https://media.electrifyamerica.com/en-us/releases/226" rel="noopener" target="_blank"
        >Learn More<arrow class="arrow"></arrow
      ></a>
    </Card>

    <div class="l-one--has-bg">
      <section class="video l-one__col">
        <iframe
          src="https://www.youtube.com/embed/JdDOtjTo7fU"
          title="Additionality"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </section>
    </div>

    <Card
      headlineLevel="h2"
      heading="Types of Solar Charging Stations and Features"
      :isReverse="true"
      headlineDisplayClass="headline3"
      class="no-bottom-padding"
    >
      <template #image>
        <picture>
          <source
            media="(min-width:750px)"
            srcset="@/assets/images/Renewable-Energy/solar-powered-charging-station.png"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/solar-powered-charging-station.png"
            alt="An Electrify America solar L2 station with chargers for two vehicles."
          />
        </picture>
      </template>
      <h4>100% Solar-Powered Level 2 Stations</h4>
      <p>
        Electrify America has invested $2 million in 30 solar-powered electric vehicle (EV) charging stations across
        rural California. These resilient Level 2 (L2) stations aren’t tied to the electrical grid, and they provide
        drivers in rural areas access to EV charging via renewable resources.
      </p>
      <p>
        Equipped with a sun-tracking solar array and energy storage, each station can charge two vehicles
        simultaneously, rain or shine. Capable vehicles can charge at speeds up to 3.5 kW.
      </p>
    </Card>

    <Card headlineLevel="h2" :isReverse="isPhone" heading="On-Site Solar Canopies" headlineDisplayClass="headline4">
      <template #image>
        <picture>
          <source media="(min-width:750px)" srcset="@/assets/images/Renewable-Energy/charging-stations-night.png" />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/charging-stations-night-mobile.png"
            alt="An Electrify America solar L2 station with chargers for two vehicles."
          />
        </picture>
      </template>
      <div class="content flex-column">
        <p>
          Stay cool while you charge at locations like our Baker, California station, where solar canopies provide shade
          and deliver energy back to the grid.
        </p>
        <p>
          These charging stations integrate with the local electrical grid and some even feature on-site energy storage,
          delivering efficient, reliable charging.
        </p>
      </div>
    </Card>

    <Card
      headlineLevel="h2"
      heading="Building a Grid-Friendly Charging Network"
      isReverse
      headlineDisplayClass="headline3"
      bgColor
    >
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/charger-installation.png"
            alt="An Electrify America charging station with battery energy storage system."
          />
        </picture>
      </template>
      <p>
        Charging multiple EVs at the same station requires lots of power. To ease electrical grid demand during peak use
        times, we've installed 150+ Battery Energy Storage Systems (BESS) at select locations."
      </p>
      <a class="button-link" rel="noopener" target="_blank" href="http://media.electrifyamerica.com/"
        >CHECK OUR MEDIA SITE FOR THE LATEST ANNOUNCEMENTS ABOUT BATTERY STORAGE <Arrow class="arrow"
      /></a>
    </Card>
    <RenewableEnergyFAQ />
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';
import RenewableEnergyFAQ from '@/components/RenewableEnergyFAQ.vue';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'renewable-energy',
  components: {
    Arrow,
    RenewableEnergyFAQ,
    Card,
    Hero,
  },
  metaInfo: {
    title: 'Renewable Energy & Sustainability | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America invests in renewable energy solutions like solar EV chargers in our effort to lower our energy-related emissions overall and improve your EV charging experience.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/renewable-energy/' }],
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(800);
    },
  },
};
</script>

<style scoped lang="scss">
.renewable-energy {
  .l-one--has-bg {
    padding-bottom: 100px;
  }
  .card.no-bottom-padding {
    @media (max-width: 750px) {
      padding-bottom: 0;
    }
  }

  h4 {
    font-weight: 400;
  }

  .arrow {
    margin-left: 8px;
    path {
      fill: $c-primary;
    }
  }

  a.button-link {
    display: block;
    margin-bottom: 32px;
    @media (min-width: 800px) {
      margin-bottom: 0;
    }
  }

  .video {
    display: flex;
    justify-content: center;
    iframe {
      width: 100%;
      height: 50.625vw;
      max-height: 442px;
      max-width: 785px;
    }
    @media (min-width: 800px) {
      padding-top: 115px;
    }
  }
}
</style>
